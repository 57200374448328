import axios from "axios";
import React, { useState } from "react";
import { Button, Form, ProgressBar } from "react-bootstrap";

function MediaConfig() {
  const [ImageList, setImageList] = useState(undefined);
  const [Files, setFiles] = useState([]);
  const [isUploading, setisUploading] = useState(false);
  const [ProgressVal, setProgressVal] = useState(0);

  const handleImageFiles = (e) => {
    //console.log("processing file");
    if (e.target.files.length > 0) {
      //console.log("Total files=" + e.target.files.length);
      let files = Array.from(e.target.files);
      setFiles(files);
    }
  };

  const uploadImageList = () => {
    const data = new FormData();
    data.append("folder", ImageList);
    for (let i = 0; i < Files.length; i++) {
      data.append("files", Files[i]);
    }
    setisUploading(true);
    setProgressVal(0)
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/uploadimg_list", data, {
        onUploadProgress: (ProgressEvent) => {
          setProgressVal((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        setisUploading(false);
        console.log(response.data);
      })
      .catch((err) => {
        setisUploading(false);
        console.log("Create product Error:" + err);
      });
   
  };

  return (
    <div>
      {" "}
      <Form>
        <div>
          <h5>Image List</h5>
          <Form.Group className="mb-3">
            <Form.Label>Select Image List</Form.Label>
            <Form.Select
              aria-label=""
              onChange={(e) => {
                setImageList(e.target.value);
              }}
            >
              <option value="0">Open this select menu</option>
              <option value="oli">Oli</option>
              <option value="family">Family</option>
              <option value="kid">Kid</option>
              <option value="jam">JAM</option>
              <option value="wyr">WYR</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <input
              type="file"
              id="mediafile"
              onChange={handleImageFiles}
              accept="image/jpg,.jpg,.png"
              multiple
            />
          </Form.Group>
          {isUploading == true ? (
            <ProgressBar className="mb-3" animated now={ProgressVal} />
          ) : (
            ""
          )}

          <Button variant="primary" onClick={uploadImageList}>
            Upload Images
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default MediaConfig;
