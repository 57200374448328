import logo from "./logo.svg";
import "./App.css";
import { Button } from "react-bootstrap";
import PlaylistConfig from "./pages/PlaylistConfig";
import MediaConfig from "./pages/MediaConfig";
import { Route, Switch } from "react-router-dom";
import Menu from "./components/Menu";
import { Container, Row, Col } from "react-bootstrap";
import Authenticate from "./pages/authenticate/Authenticate";
import { connect } from "react-redux";

function App(props) {
  return (
    <div>
      <Container fluid>
        {props.userData &&
        props.userData.isUserAuthenticated &&
        props.userData.isUserAuthenticated.isgood == true ? (
          <Row>
            <Col md={2}>
              <Menu />
            </Col>
            <Col>
              <Switch>
                <Route path="/" exact>
                  <PlaylistConfig />
                </Route>
                <Route path="/media">
                  <MediaConfig />
                </Route>
              </Switch>
            </Col>
          </Row>
        ) : (
          <Authenticate />
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.UserReducer,
  };
};

export default connect(mapStateToProps)(App);
