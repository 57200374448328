import React from "react";
import { Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { user_authentication } from "../redux/users/useAction";
import { connect } from "react-redux";
function Menu(props) {
  return (
    <div>
      <h3>Config Menu</h3>
      <LinkContainer to="/">
        <Button variant="primary" className="w-100 mb-2">
          Playlist Config
        </Button>
      </LinkContainer>
      <LinkContainer to="/media">
        <Button variant="primary" className="w-100 mb-2">
          Media Config
        </Button>
      </LinkContainer>

      <Button
        onClick={() => {
          props.logout();
        }}
        variant="danger"
        className="w-100"
      >
        LogOut
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.UserReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(user_authentication(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
