import React, { useState } from "react";
import { Form, Button, Image,ProgressBar } from "react-bootstrap";
import axios from "axios";

function PlaylistConfig() {
  const [Song, setSong] = useState(undefined);
  const [Artist, setArtist] = useState(undefined);
  const [File, setFile] = useState(undefined);
  const [Cover, setCover] = useState(undefined);
  const [Id, setId] = useState(undefined);

  const [redableCover, setredableCover] = useState(undefined);
  const [readableAudio, setreadableAudio] = useState(undefined);

  const [isUploading, setisUploading] = useState(false);
  const [ProgressVal, setProgressVal] = useState(0);

  const handleFileCover = (e) => {
    //console.log("processing file");
    if (e.target.files.length > 0) {
      //console.log("Total files=" + e.target.files.length);
      let file = e.target.files[0];
      setCover(file);
      let readCover = URL.createObjectURL(e.target.files[0]);
      setredableCover(readCover);
    }
  };

  const viewCover = () => {
    if (redableCover) {
      return <Image src={redableCover} width="200" height="200" />;
    } else return <p>no Cover set yet</p>;
  };

  const handleFileAudio = (e) => {
    //console.log("processing file");
    if (e.target.files.length > 0) {
      //console.log("Total files=" + e.target.files.length);
      let file = e.target.files[0];
      setFile(file);
      let readFile = URL.createObjectURL(e.target.files[0]);
      setreadableAudio(readFile);
    }
  };

  const viewAudio = () => {
    if (readableAudio) {
      return (
        <audio controls key={File.name}>
          <source src={readableAudio} type="audio/mpeg" />
        </audio>
      );
    } else return <p>no Audio set yet</p>;
  };

  const uploadPlaylist = () => {
    const data = new FormData();
    data.append("id", Id);
    data.append("files", Cover);
    data.append("files", File);
    setisUploading(true);
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/uploadAudio", data, {
        onUploadProgress: (ProgressEvent) => {
          setProgressVal((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        console.log(response.data);
        setisUploading(false);
      })
      .catch((err) => {
        console.log("Upload aud_img Error:" + err);
        setisUploading(false);
      });
  };

  const submitMedia = () => {
    const data = {
      song: Song,
      artist: Artist,
    };
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/addtolist1", data)
      .then((response) => {
        console.log(response.data.id);
        setId(response.data.id);
      })
      .catch((err) => {
        console.log("Submit aud Error:" + err);
      });
  };

  return (
    <div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Id</Form.Label>
          <Form.Control
            value={Id}
            type="text"
            placeholder="Fetched auto"
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Song</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter song"
            onChange={(e) => {
              setSong(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Artist</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter artist"
            onChange={(e) => {
              setArtist(e.target.value);
            }}
          />
        </Form.Group>

        <Button variant="primary" onClick={submitMedia}>
          Submit
        </Button>

        {Id ? (
          <div>
            <h5>Cover Image</h5>
            <Form.Group className="mb-3">
              <input
                type="file"
                id="mediafile"
                onChange={handleFileCover}
                accept="image/png,.jpg"
              />
            </Form.Group>
            <div className="d-flex">{viewCover()}</div>
            <h5>Audio File</h5>
            <Form.Group className="mb-3">
              <input
                type="file"
                id="mediafile"
                onChange={handleFileAudio}
                accept="music/mp3,.mp3,.wav"
              />
            </Form.Group>
            <div className="d-flex">{viewAudio()}</div>
            {isUploading == true ? (
              <ProgressBar className="mb-3" animated now={ProgressVal} />
            ) : (
              ""
            )}
            <Button variant="primary" onClick={uploadPlaylist}>
              Upload Media
            </Button>
          </div>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
}

export default PlaylistConfig;
